import gql from "graphql-tag";

const LEAD_STAGES_QUERY = gql`
{
  staging_leadStages {
    id
    stageName
    color
  }
}
`;

export default LEAD_STAGES_QUERY;