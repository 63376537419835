import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../Login/Login';
import auth0Client from '../Auth/Auth';

function SecuredRoute(props) {
  const { component: Component, path, checkingSession } = props;
  return (
    <Route
      path={path}
      render={() =>
        auth0Client.isAuthenticated() ? (
          <Component />
        ) : (
          <Login loading={checkingSession} />
          )
      }
    />
  );
}

export default SecuredRoute;