import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { Query } from '@apollo/client/react/components';
import POSTS_QUERY from '../Data/Queries/PostsQuery';
import './Posts.css';
import coverimage from '../img/newarrival_img_thumb.jpg';

function renderPost(postData) {
  return (
    <div className="col-md-4" key={postData.id}>
      <div className="card mb-4 shadow-sm">
        {postData.vehicle_images ? (
          <img src={JSON.parse(postData.vehicle_images)[0]} className="card-img-top" alt="Vehicle"></img>
        ) : (
            <img src={coverimage} className="card-img-top" alt="Vehicle"></img>
          )}
        <div className="card-body">
          <div className="Post-caption">
            <strong>VIN: {postData.vehicle_vin}</strong>
            <br></br>
            <strong>{postData.vehicle_year} {postData.vehicle_make} {postData.vehicle_model}</strong>
            <br></br>
            {postData.vehicle_trim}, {postData.vehicle_transmission}, {postData.vehicle_drivetrain}, {postData.vehicle_engine}, {postData.vehicle_mileage} miles
            <br></br><span className="vehiclePrice text-success" id="downPayment">${postData.downpayment} down payment</span>
            <br></br>
          </div>
        </div>
        <div className="card-body">
          <Link to={{
            pathname: `posts/${postData.id}`,
            state: {
              postData: postData
            }
          }} className="card-link"><strong>View Post</strong></Link>
        </div>
      </div>
    </div >
  )
}

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      filteredPosts: null,
      searchQuery: ""
    };
  }

  getCsvHeaders() {
    return [
      { label: "vehicle_id", key: "vehicle_id" },
      { label: "title", key: "title" },
      { label: "description", key: "description" },
      { label: "url", key: "url" },
      { label: "make", key: "make" },
      { label: "model", key: "model" },
      { label: "year", key: "year" },
      { label: "mileage.value", key: "mileage.value" },
      { label: "mileage.unit", key: "mileage.unit" },
      { label: "image[0].url", key: "image.url" },
      { label: "transmission", key: "transmission" },
      { label: "fuel_type", key: "fuel_type" },
      { label: "body_style", key: "body_style" },
      { label: "drivetrain", key: "drivetrain" },
      { label: "vin", key: "vin" },
      { label: "price", key: "price" },
      { label: "address", key: "address" },
      { label: "exterior_color", key: "exterior_color" },
      { label: "sale_price", key: "sale_price" },
      { label: "availability", key: "availability" },
      { label: "state_of_vehicle", key: "state_of_vehicle" },
      { label: "latitude", key: "latitude" },
      { label: "longitude", key: "longitude" },
    ];
  }

  getCsvData() {
    let postData = this.state.posts;
    let csvData = [];
    function createCsvRow(vehicle) {
      let vehicleTitle = vehicle.vehicle_year + " " + vehicle.vehicle_make + " " + vehicle.vehicle_model;
      let vehicleImage = vehicle.poster_image ? vehicle.poster_image : "https://sanjoseamigos.com/static/media/newarrival_img.51731425.jpg";
      let vehiclePrice = vehicle.vehicle_price + " USD";
      let bodyStyle;
      if (vehicle.vehicle_body === "SportsCar") {
        bodyStyle = "Coupe";
      } else {
        bodyStyle = vehicle.vehicle_body;
      }
      let vehicleDrivetrain;
      if (vehicle.vehicle_drivetrain === "2WD") {
        vehicleDrivetrain = "4X2";
      } else if (vehicle.vehicle_drivetrain === "4WD") {
        vehicleDrivetrain = "4X4";
      } else {
        vehicleDrivetrain = vehicle.vehicle_drivetrain;
      }
      let rowObject = {
        vehicle_id: vehicle.id,
        title: vehicleTitle,
        description: "Easy financing available, " + vehicle.notable_feature_5,
        url: "https://www.sanjoseamigos.com",
        make: vehicle.vehicle_make,
        model: vehicle.vehicle_model,
        year: vehicle.vehicle_year,
        mileage: {
          value: vehicle.vehicle_mileage,
          unit: "MI"
        },
        image: {
          url: vehicleImage
        },
        transmission: "Automatic",
        fuel_type: "Gasoline",
        body_style: bodyStyle,
        drivetrain: vehicleDrivetrain,
        vin: vehicle.vehicle_vin,
        price: vehiclePrice,
        address: "{addr1: '1660 W San Carlos St', city: 'San Jose', region: 'CA', postal_code: '95128', country: 'US'}",
        exterior_color: "N/A",
        sale_price: vehiclePrice,
        availability: "AVAILABLE",
        state_of_vehicle: "USED",
        latitude: 37.323186,
        longitude: -121.9243713
      }
      csvData.push(rowObject);
    }
    postData.map(post => createCsvRow(post));
    return csvData;
  }

  onSearchReset() {
    this.setState({
      searchQuery: "",
      filteredPosts: null
    })
  }

  onSearch() {
    let searchResults = this.state.posts.filter(post => post.vehicle_vin.includes(this.state.searchQuery));
    this.setState({
      filteredPosts: searchResults
    })
  }

  render() {
    return (
      <div>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
            <li className="breadcrumb-item active">Posts</li>
          </ol>
        </nav>
        <section className="jumbotron text-center pt-3">
          <div className="container">
            <h1 className="jumbotron-heading">Posts</h1>
            <p className="lead text-muted">See and edit inventory on your website</p>
            <CSVLink data={this.getCsvData()} headers={this.getCsvHeaders()}>Download CSV Inventory</CSVLink>
          </div>
        </section>
        <div className="album pt-2">
          <div className="container">
            <Query query={POSTS_QUERY} onCompleted={data => this.setState({ posts: data.posts })}>
              {({ loading, error, data }) => {
                if (loading) return <div>Loading...</div>
                if (error) return <div>Error</div>

                let displayPosts = this.state.filteredPosts || this.state.posts;

                return (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <h6 className="text-fade">Total of {displayPosts.length} vehicles found.</h6>
                        <div className="form-inline row">
                          <div className="form-group col-8">
                            <input type="text" value={this.state.searchQuery} onChange={obj => this.setState({searchQuery: obj.target.value})} className="form-control w-100" placeholder="Filter results"></input>
                          </div>
                          <div className="col-4">
                            <button className="btn btn-default" onClick={() => this.onSearchReset()}>Reset</button>
                            <button className="btn btn-primary" onClick={() => this.onSearch()}>Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-5">
                      {displayPosts.map(post => renderPost(post))}
                    </div>
                  </>
                )
              }}
            </Query>
          </div>
        </div>
      </div>
    );
  }
}

export default Posts;