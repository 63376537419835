import gql from "graphql-tag";

const LEADS_QUERY = gql`
query ($leadStageId: uuid){
  staging_leads(where: {leadStage: {_eq: $leadStageId}}) {
    id
    leadDetailsRelationship {
      firstName
      lastName
    }
  }
}
`;

export default LEADS_QUERY;