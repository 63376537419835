import { gql } from 'apollo-boost';

const UPDATE_IMAGES = gql`
mutation ($vehicleId: uuid!, $images: jsonb!) {
  update_posts(_set: {vehicle_images: $images}, where: {id: {_eq: $vehicleId}}) {
    returning {
      id
      vehicle_images
    }
  }
}
`;

export default UPDATE_IMAGES;