import React, { Component } from 'react';
import MessageList from './Messenger/MessageList';
import './Leads.css';
import './Toolbar.css';
import LeadsList from './LeadsList/LeadsList';
import ToolbarButtons from './ToolbarButtons';

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedView: 1,
      viewOptions: [{ id: 0, iconName: "IoIosMailOpen" }, { id: 1, iconName: "IoLogoFacebook" }, { id: 2, iconName: "IoIosChatbubbles" }, { id: 3, iconName: "IoMdClipboard" }, { id: 4, iconName: "IoIosListBox" }, { id: 5, iconName: "IoIosInformationCircleOutline" }]
    };
  }

  renderToolbarOptions(view) {
    return (
      <div key={view.id} onClick={() => this.selectView(view.id)}>
        <ToolbarButtons tag={view.iconName} isActive={(this.state.selectedView === view.id ? true : false)} />
      </div>
    )
  }

  selectView(viewId) {
    this.setState({
      selectedView: viewId
    });
  }

  renderLeadView(viewId) {
    if(viewId === 0) {
      return <div className="viewContainer"><h4>Emails</h4></div>
    } else if (viewId === 1) {
      return <MessageList />
    } else if (viewId === 2) {
      return <div className="viewContainer"><h4>SMS Messages</h4></div>
    } else if (viewId === 3) {
      return <div className="viewContainer"><h4>Credit App</h4></div>
    } else if (viewId === 4) {
      return <div className="viewContainer"><h4>Credit History</h4></div>
    } else {
      return <div className="viewContainer"><h4>Lead Details</h4></div>
    }
  }

  render() {
    return (
      <div className="leads">

        <div className="scrollable sidebar">
          <LeadsList apollo_client={this.props.apollo_client} />
        </div>

        <div className="scrollable content">
          <div className="toolbar">
            <div className="left-items"></div>
            <h1 className="toolbar-title">Lead</h1>
            <div className="right-items">
              {this.state.viewOptions.map(view => this.renderToolbarOptions(view))}
            </div>
          </div>
          {this.renderLeadView(this.state.selectedView)}
        </div>
      </div>
    );
  }
}

export default Leads;