import gql from "graphql-tag";

const STORIES_QUERY = gql`
{
  stories {
    id
    imageUrl
    videoUrl
  }
}
`;

export default STORIES_QUERY;