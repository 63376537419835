import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from 'apollo-boost';
import auth0Client from './Auth/Auth';
import NavBar from './NavBar/NavBar';
import Dashboard from './Dashboard/Dashboard';
import Posts from './Posts/Posts';
import Post from './Post/Post';
import Stories from './Stories/Stories';
import NewStory from './Stories/NewStory';
import Leads from './Leads/Leads';
import Callback from './Auth/Callback';
import SecuredRoute from './SecuredRoute/SecuredRoute';
import './App.css';

const client = new ApolloClient({
  uri: "https://sanjoseamigos.herokuapp.com/v1/graphql",
  request: async (operation) => {
    const token = await auth0Client.getIdToken();
    operation.setContext({
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
})

function LeadsRoute() {
  return <Leads apollo_client={client} />;
}

function PostsRoute() {
  return <Posts apollo_client={client} />;
}

function PostRoute() {
  return <Post apollo_client={client} />;
}

function NewStoryRoute() {
  return <NewStory apollo_client={client} />;
}

function StoriesRoute() {
  return <Stories apollo_client={client} />;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    }
  }

  async componentDidMount() {
    if (this.props.location.pathname === '/callback') {
      this.setState({ checkingSession: false });
      return;
    }
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
    }
    this.setState({ checkingSession: false });
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <NavBar />
        <Switch>
          <SecuredRoute exact path='/' component={Dashboard} checkingSession={this.state.checkingSession} />
          <SecuredRoute exact path='/leads' component={LeadsRoute} checkingSession={this.state.checkingSession} />
          <SecuredRoute exact path='/leads/availableLeads' component={LeadsRoute} checkingSession={this.state.checkingSession} />
          <SecuredRoute exact path='/posts' component={PostsRoute} checkingSession={this.state.checkingSession} />
          <SecuredRoute exact path='/posts/:id' component={PostRoute} checkingSession={this.state.checkingSession} />
          <SecuredRoute exact path='/stories' component={StoriesRoute} checkingSession={this.state.checkingSession} />
          <SecuredRoute exact path='/stories/new' component={NewStoryRoute} checkingSession={this.state.checkingSession} />
          <Route exact path='/callback' component={Callback} />
        </Switch>
      </ApolloProvider>
    );
  }
}

export default withRouter(App);
