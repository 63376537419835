import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Query, Mutation } from '@apollo/client/react/components';
import STORIES_QUERY from '../Data/Queries/StoriesQuery';
import DELETE_STORY from '../Data/Mutations/DeleteStory';
import './Stories.css';

function renderStory(story) {
  const storyId = story.id;
  return (
    <div className="col-md-4" key={story.id}>
      <div className="card mb-4 shadow-sm">
        <video className="bd-placeholder-img card-img-top" playsInline controls poster={story.imageUrl} src={story.videoUrl} />
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <Mutation
              mutation={DELETE_STORY}
              variables={{ storyId }}
              refetchQueries={[{ query: STORIES_QUERY }]}>
              {postMutation => <button className="btn btn-sm btn-outline-danger" onClick={postMutation}>DELETE STORY</button>}
            </Mutation>
          </div>
        </div>
      </div>
    </div>
  )
}

class Stories extends Component {

  render() {
    return (
      <div>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
            <li className="breadcrumb-item active">Stories</li>
          </ol>
        </nav>
        <section className="jumbotron text-center pt-3">
          <div className="container">
            <h1 className="jumbotron-heading">Stories</h1>
            <p className="lead text-muted">See and edit stories on your website</p>
            <p className="lead"><Link to="/stories/new" className="btn btn-primary btn-lg">Create New Story</Link></p>
          </div>
        </section>
        <div className="album py-5">
          <div className="container">
            <Query query={STORIES_QUERY}>
              {({ loading, error, data }) => {
                if (loading) return <div>Loading...</div>
                if (error) return <div>Error</div>
  
                const stories = data.stories;
  
                return (
                  <div className="row">
                    {stories.map(story => renderStory(story))}
                  </div>
                )
              }}
            </Query>
          </div>
        </div>
      </div>
    );
  }
}

export default Stories;