import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import axios from 'axios';
import ADD_STORY from '../Data/Mutations/NewStory';
import STORIES_QUERY from '../Data/Queries/StoriesQuery';
import './NewStory.css';

const uuidv1 = require('uuid/v1');

class NewStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputVideoUrl: "",
      inputFile: "",
      s3VideoUrl: "",
      s3ImageUrl: "",
      disabledButton: false,
      submitDisabled: true,
    }
    this.videoRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  generateThumbnail(files) {
    this.setState({
      inputVideoUrl: window.URL.createObjectURL(files[0]),
      inputFile: files[0]
    });
  }

  videoRefCreated() {
    var video = this.videoRef.current;
    video.play();
    setTimeout(() => {
      video.pause();
      this.canvasRef.current.width = video.videoWidth;
      this.canvasRef.current.height = video.videoHeight;
      this.canvasRef.current.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    }, 500);
  }

  displayVideo() {
    if (!!this.state.inputVideoUrl) {
      return (
        <div>
          <button className="btn btn-primary mt-3" onClick={(e) => this.uploadVideo(e.target)} disabled={this.state.disabledButton}>Upload video</button>
          <video ref={this.videoRef} controls muted playsInline src={this.state.inputVideoUrl} onLoadedData={() => this.videoRefCreated()} className="video-preview" />
        </div>
      )
    } else {
      return null;
    }
  }

  submitStory(e, postMutation) {
    e.target.innerText = "Submitting";
    this.setState({
      submitDisabled: true
    });
    postMutation().then(res => {
      this.props.history.push("/stories");
    });
  }

  uploadVideo(button) {
    button.innerText = "Uploading...";
    this.setState({
      disabledButton: true
    });

    let file = this.state.inputFile;
    let image = this.canvasRef.current.toDataURL("image/jpeg");
    let bufferedImage = new Buffer(image.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    let imageName = uuidv1();
    let imageType = "jpg";
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileName = uuidv1();
    let fileType = fileParts[1];
    axios.post("https://amigos-mailer.herokuapp.com/fileUpload", {
      fileName: fileName,
      fileType: fileType
    })
      .then(response => {
        var returnData = response.data.returnData;
        var signedRequest = returnData.signedRequest;
        var url = returnData.url;
        this.setState({ s3VideoUrl: url })

        // Put the fileType in the headers for the upload
        var options = {
          headers: {
            'Content-Type': fileType
          }
        };
        axios.put(signedRequest, file, options)
          .then(result => {
            axios.post("https://amigos-mailer.herokuapp.com/fileUpload", {
              fileName: imageName,
              fileType: imageType
            })
              .then(response => {
                var returnData = response.data.returnData;
                var signedRequest = returnData.signedRequest;
                var url = returnData.url;
                this.setState({ s3ImageUrl: url })

                // Put the fileType in the headers for the upload
                var options = {
                  headers: {
                    'Content-Type': imageType
                  }
                };
                axios.put(signedRequest, bufferedImage, options)
                  .then(result => {
                    button.innerText = "Upload done!";
                    button.classList.replace("btn-primary", "btn-success");
                    this.setState({submitDisabled: false})
                  })
                  .catch(error => {
                    console.log(error);
                  })
              })
              .catch(error => {
                console.log(error);
              })
          })
          .catch(error => {
            console.log(error);
          })
      })
      .catch(error => {
        console.log(error);
      })
  }

  render() {
    const { s3ImageUrl, s3VideoUrl } = this.state;
    return (
      <div>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
            <li className="breadcrumb-item"><Link to="/stories">Stories</Link></li>
            <li className="breadcrumb-item active">New Story</li>
          </ol>
        </nav>
        <section className="jumbotron text-center pt-3">
          <div className="container">
            <h1 className="jumbotron-heading">New Story</h1>
            <p className="lead text-muted">Create and add new story</p>
          </div>
        </section>
        <div className="album py-5">
          <div className="container">

            <div className="row">
              <div className="col-md-6">
                <h5>1. Select video to upload</h5>
                <input type="file" id="video-input" onChange={(e) => this.generateThumbnail(e.target.files)} />
                {this.displayVideo()}
                <canvas id="canvas" ref={this.canvasRef}></canvas>
              </div>
              <div className="col-md-6">
                <h5 className={this.state.submitDisabled ? "text-muted" : ""}>2. Post to website</h5>
                <Mutation
                  mutation={ADD_STORY}
                  variables={{ s3ImageUrl, s3VideoUrl }}
                  refetchQueries={[{ query: STORIES_QUERY }]}>
                  {postMutation => <button className="btn btn-success" onClick={(e) => this.submitStory(e, postMutation)} disabled={this.state.submitDisabled}>Submit to website</button>}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewStory);