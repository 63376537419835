import React, { Component } from 'react';
import { IoLogoFacebook, IoIosChatbubbles, IoIosInformationCircleOutline, IoIosMailOpen, IoMdClipboard, IoIosListBox } from "react-icons/io";
import './ToolbarButtons.css';

class ToolbarButtons extends Component {
  components = {
    IoLogoFacebook: IoLogoFacebook,
    IoIosChatbubbles: IoIosChatbubbles,
    IoIosInformationCircleOutline: IoIosInformationCircleOutline,
    IoIosMailOpen: IoIosMailOpen, 
    IoMdClipboard: IoMdClipboard,
    IoIosListBox: IoIosListBox
  };
  render() {
    const TagName = this.components[this.props.tag];
    return <TagName className={"rightToolbarIcon " + (this.props.isActive ? 'active' : '')} />
  }
}
export default ToolbarButtons;