import gql from "graphql-tag";

const POSTS_QUERY = gql`
{
  posts {
    id
    downpayment
    notable_feature_1
    notable_feature_2
    notable_feature_3
    notable_feature_4
    notable_feature_5
    vehicle_body
    vehicle_drivetrain
    vehicle_engine
    vehicle_make
    vehicle_mileage
    vehicle_model
    vehicle_price
    vehicle_transmission
    vehicle_trim
    vehicle_vin
    vehicle_year
    vehicle_images
  }
}
`;

export default POSTS_QUERY;