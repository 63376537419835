import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import UPDATE_IMAGES from '../../Data/Mutations/UpdateImages';
import { useMutation } from '@apollo/client';
const uuidv1 = require('uuid/v1');

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 16;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 ${grid}px 0 0`,
  width: "200px",

  // change background colour if dragging
  background: isDragging ? '#e4e4e4' : '#F1F1F1',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#e4e4e4' : '#F1F1F1',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

function VehicleImages({ vehicleId }) {
  const [isUploading, setIsUploading] = useState(false);
  const [items, setItems] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [updateImages] = useMutation(UPDATE_IMAGES);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsReordered = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(itemsReordered);
  }

  const onImagesSelectedToUpload = (res) => {
    let files = res.currentTarget.files;
    let fileObjects = [];
    for (var i = 0; i < files.length; i++) {
      if (!files[i].type.match('image')) continue;
      fileObjects.push({ id: i.toString(), file: files[i], fileUrl: URL.createObjectURL(files[i]) });
    }
    setItems(fileObjects);
  }

  const removeImage = (index) => {
    var updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  }

  const saveImages = (images) => {
    updateImages({
      variables:
      {
        images: JSON.stringify(images),
        vehicleId: vehicleId
      }
    }).then(res => {
      setIsUploading(false);
      setItems([]);
    });
  };

  const uploadImages = () => {
    var images = [];
    let counter = 0;
    setIsUploading(true);
    for (var i = 0; i < items.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(items[i].file);
      let fileParts = items[i].file.name.split('.');
      let fileName = uuidv1();
      let fileType = fileParts[1];
      let fileId = i;
      let url = `https://thrivemotors-images.s3.amazonaws.com/images/${fileName}.${fileType}`;
      images.splice(i, 0, url);
      reader.onloadend = () => {
        axios.post("https://amigos-mailer.herokuapp.com/imageUpload", {
          fileName: fileName,
          fileType: fileType,
          file: reader.result,
          fileId: fileId
        }).then(response => {
          counter++;
          if (counter === items.length) {
            setImageUrls(images);
            saveImages(images);
          }
        })
      }
    }
  }
  return (
    <div className="card">
      <div className="card-header">
        <h5 className="mb-0 card-title">Upload Images</h5>
      </div>
      <div className="card-body">
        <div className="my-3">
          {items.length > 0 ? (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              className="flex-column"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}>
                              <img
                                src={item.fileUrl}
                                style={{ width: "200px" }}
                                alt="Vehicle"
                              >
                              </img>
                              <button className="btn btn-danger btn-block" onClick={() => removeImage(index)}>Remove</button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <button className="mt-3 btn btn-success" disabled={isUploading} onClick={() => uploadImages()}>{isUploading ? "Uploading..." : "Upload and Save Images"}</button>
            </>
          ) : (
              <div className="my-3">
                <input type="file" multiple={true} onChange={res => onImagesSelectedToUpload(res)}></input>
              </div>
            )}
        </div>
      </div>
    </div >
  )
}

export default VehicleImages;