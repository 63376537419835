import gql from "graphql-tag";

const ADD_STORY = gql`
mutation ($s3ImageUrl: String!, $s3VideoUrl: String!) {
  insert_stories(
    objects: [
      {
        imageUrl: $s3ImageUrl,
        videoUrl: $s3VideoUrl
      },
    ]
  ) {
    affected_rows
  }
}
`;

export default ADD_STORY;