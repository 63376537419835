import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import ImageGallery from 'react-image-gallery';
import POST_BY_ID_QUERY from '../Data/Queries/PostByIdQuery';
import './Post.css';
import VehicleImages from './components/VehicleImages';

const Post = ({ match }) => {
  const postId = match.params.id;
  let images;
  return (
    <div>
      <nav>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
          <li className="breadcrumb-item"><Link to="/posts">Posts</Link></li>
          <li className="breadcrumb-item active">Vehicle Details</li>
        </ol>
      </nav>
      <Query query={POST_BY_ID_QUERY} variables={{ postId }}>
        {({ loading, error, data }) => {
          if (loading) return <div>Loading...</div>
          if (error) return <div>Error</div>
          if (!data.posts.length) return <div>No data.</div>

          const postData = data.posts[0];
          if (postData.vehicle_images) {
            let imagesTemp = JSON.parse(postData.vehicle_images);
            images = [];
            for (var i = 0; i < imagesTemp.length; i++) {
              if (imagesTemp[i].startsWith("http")) {
                images.push({ original: imagesTemp[i], thumbnail: imagesTemp[i] });
              }
            }
          }

          return (
            <>
              <section className="jumbotron text-center pt-3">
                <div className="container">
                  <h1 className="jumbotron-heading">{postData.vehicle_year} {postData.vehicle_make} {postData.vehicle_model}</h1>
                  <p className="lead text-muted">{postData.vehicle_vin}</p>
                </div>
              </section>
              <div className="album py-5">
                <div className="container">
                  {postData.vehicle_images ? (
                    <ImageGallery items={images} />
                  ) : (
                      <h5>No existing images uploaded.</h5>
                    )}
                  <VehicleImages vehicleId={postData.id} />
                </div>
              </div>
            </>
          )
        }}
      </Query>
    </div>
  );
}

export default withRouter(Post);