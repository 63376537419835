import gql from "graphql-tag";

const DELETE_STORY = gql`
mutation ($storyId: uuid) {
  delete_stories(
    where: {
      id: {
        _eq: $storyId
      }
    }
  ){
  returning {
      id
    }
  }
}
`;

export default DELETE_STORY;