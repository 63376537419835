import React, { Component } from 'react';
import LeadsListItem from './LeadsListItem';
import { IoIosBookmark, IoMdAddCircleOutline } from "react-icons/io";
import { Query } from '@apollo/client/react/components';
import LEAD_STAGES_QUERY from '../../Data/Queries/LeadStagesQuery';
import LEADS_BY_STAGE_COUNT_QUERY from '../../Data/Queries/LeadsByStageCountQuery';
import LEADS_QUERY from '../../Data/Queries/LeadsQuery';

import './LeadsList.css';

export default class LeadsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      leadStageSelected: null,
    };
  }

  selectLeadStage(leadStage, apollo_client) {
    this.setState({
      leadStageSelected: leadStage.id
    });
    this.getLeads(leadStage.id, apollo_client);
  }

  getLeads = (leadStageId, apollo_client) => {
    apollo_client.query({
      query: LEADS_QUERY,
      variables: { leadStageId }
    }).then(response => {
      this.setState(prevState => {
        let leads = response.data.staging_leads.map(lead => {
          return {
            id: lead.id,
            photo: "https://i.pravatar.cc/50",
            name: `${lead.leadDetailsRelationship.firstName} ${lead.leadDetailsRelationship.lastName}`,
            text: 'Hello world! This is a long message that needs to be truncated.'
          };
        });

        return { leads };
      });
    });
  }

  renderLeadStage(leadStage) {
    const leadStageId = leadStage.id;
    return (
      <div key={leadStage.id} className={"leadStage " + (this.state.leadStageSelected === leadStage.id ? 'active' : '')} onClick={() => this.selectLeadStage(leadStage, this.props.apollo_client)}>
        <div className="leftSide">
          <IoIosBookmark className={"bookmark text-" + leadStage.color} />
          <span className="leadStagesTitle">{leadStage.stageName}</span>
        </div>
        <Query query={LEADS_BY_STAGE_COUNT_QUERY} variables={{ leadStageId }}>
          {({ loading, error, data }) => {
            if (loading) return <div>Loading...</div>
            if (error) return <div>Error</div>
            const leadsCount = data.staging_leads_aggregate.aggregate.count;

            return (
              <div className="leadsCount">{leadsCount}</div>
            )
          }}
        </Query>
      </div>
    )
  }

  render() {
    return (
      <div className="leads-list">
        <div className="leads-toolbar">
          <div className="leads-search">
            <input
              type="search"
              className="leads-search-input"
              placeholder="Search Leads"
            />
          </div>
          <IoMdAddCircleOutline className="toolbarIcon" />
        </div>
        <div className="leadTypes">
          <Query query={LEAD_STAGES_QUERY} onCompleted={data => this.selectLeadStage(data.staging_leadStages[0], this.props.apollo_client)}>
            {({ loading, error, data }) => {
              if (loading) return <div>Loading...</div>
              if (error) return <div>Error</div>
              const leadStages = data.staging_leadStages;

              return (
                <div className="row">
                  {leadStages.map(leadStage => this.renderLeadStage(leadStage))}
                </div>
              )
            }}
          </Query>
        </div>
        {
          this.state.leads.map(lead =>
            <LeadsListItem
              key={lead.id}
              data={lead}
            />
          )
        }
      </div>
    );
  }
}