import React, { Component } from 'react';
import shave from 'shave';

import './LeadsListItem.css';

export default class LeadsListItem extends Component {
  componentDidMount() {
    shave('.lead-snippet', 20);
  }

  render() {
    const { photo, name, text } = this.props.data;

    return (
      <div className="lead-list-item">
        <img className="lead-photo" src={photo} alt="lead" />
        <div className="lead-info">
          <h1 className="lead-title">{ name }</h1>
          <p className="lead-snippet">{ text }</p>
        </div>
      </div>
    );
  }
}