import React from 'react';
import {Link} from 'react-router-dom';

function Dashboard() {
  return (
    <div>
      <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">Dashboard</h1>
        <p className="lead">Select your next step below</p>
      </div>
      <div className="container">
        <div className="card-deck mb-3 text-center">
          {/* <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">CRM</h4>
            </div>
            <div className="card-body">
            <Link to="/leads" className="btn btn-lg btn-block btn-primary">Leads</Link>
            </div>
          </div> */}
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">Inventory</h4>
            </div>
            <div className="card-body">
              <Link to="/posts" className="btn btn-lg btn-block btn-primary">Posts</Link>
            </div>
          </div>
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal">Stories</h4>
            </div>
            <div className="card-body">
              <Link to="/stories" className="btn btn-lg btn-block btn-primary">Stories</Link>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Dashboard;