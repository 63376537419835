import React from 'react';
import { withRouter } from 'react-router-dom';
import auth0Client from '../Auth/Auth';

function Login(props) {
  if (!!props.loading) {
    return (
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <main role="main" className="inner cover text-center">
          <h1 className="cover-heading">Loading...</h1>
          <p className="lead">Please wait while we load the website</p>
        </main>
      </div>
    )
  }

  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
      <main role="main" className="inner cover text-center">
        <h1 className="cover-heading">Log in</h1>
        <p className="lead">Please click the button below to log in.</p>
        <p className="lead">
          <button className="btn btn-dark btn-lg" onClick={auth0Client.signIn}>Sign In</button>
        </p>
      </main>
    </div>
  );
}

export default withRouter(Login);