import gql from "graphql-tag";

const LEADS_BY_STAGE_COUNT_QUERY = gql`
query ($leadStageId: uuid){
  staging_leads_aggregate(where: {leadStage: {_eq: $leadStageId}}) {
    aggregate {
      count(columns: id)
    }
  }
}
`;

export default LEADS_BY_STAGE_COUNT_QUERY;